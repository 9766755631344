import convertToGridConfig, {InitialGridConfig} from "../../../../../components/ui/Grid/Telerik/types/GridConfig";
import {UserRole} from "../../../../../app/enums/UserRole";
import GridDefaultHeaderTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultHeaderTemplate/GridDefaultHeaderTemplate";
import GridDefaultCellTemplate
    from "../../../../../components/ui/Grid/Telerik/components/GridDefaultCellTemplate/GridDefaultCellTemplate";
import {GRID_SELECTED_FIELD} from "../../../../../components/ui/Grid/Telerik/utils/data";
import JobCellTemplate from "../templates/JobTemplate/JobCellTemplate";
import GroupBaseTemplate from "../templates/GroupBaseTemplate/GroupBaseTemplate";
import IsNewTemplate from "../templates/IsNewTemplate/IsNewTemplate";
import OtmStatusTemplate from "../templates/OtmStatusTemplate/OtmStatusTemplate";
import IndicatorTemplate from "../templates/IndicatorTemplate/IndicatorTemplate";
import ShipmentNumberTemplate from "../templates/ShipmentNumberTemplate/ShipmentNumberTemplate";
import PaidStatusTemplate from "../templates/PaidStatusTemplate/PaidStatusTemplate";
import TrackingTemplate from "../templates/TrackingTemplate/TrackingTemplate";
import ReleaseNumberTemplate from "../templates/ReleaseNumberTemplate/ReleaseNumberTemplate";
import NeedTrackingTemplate from "../templates/TrackingTemplate/NeedTrackingTemplate";
import ServiceProviderTemplate from "../templates/ServiceProviderTemplate/ServiceProviderTemplate";
import TransportOrderTemplate from "../templates/TransportOrderTemplate/TransportOrderTemplate";
import CmrTemplate from "../templates/CmrTemplate/CmrTemplate";
import BorderCrossingTemplate from "../templates/BorderCrossingTemplate/BorderCrossingTemplate";
import ManagerTemplate from "../templates/ManagerTemplate/ManagerTemplate";
import CustomsEtaTemplate from "../templates/CustomsEtaTemplate/CustomsEtaTemplate";
import NotesTemplate from "../templates/NotesTemplate/NotesTemplate";
import CostNettoTemplate from "../templates/CostNettoTemplate/CostNettoTemplate";
import CostTemplate from "../templates/CostTemplate/CostTemplate";
import RefNumTemplate from "../templates/RefNumTemplate/RefNumTemplate";
import LoadingNumberTemplate from "../templates/LoadingNumberTemplate/LoadingNumberTemplate";
import CountryTemplate from "../templates/CountryTemplate/CountryTemplate";
import ShipmentAddressTemplate from "../templates/ShipmentAddressTemplate/ShipmentAddressTemplate";
import ShipmentEventTemplate from "../templates/ShipmentEventTemplate/ShipmentEventTemplate";
import DateTemplate from "../templates/DateTemplate/DateTemplate";
import TransportTemplate from "../templates/TransportTemplate/TransportTemplate";
import TagsGridCellTemplate
    from "../../../../../components/shared/components/Tag/components/TagsGridCellTemplate/TagsGridCellTemplate";
import ShipmentAddressDateTemplate from "../templates/ShipmentAddressDateTemplate/ShipmentAddressDateTemplate";
import AsstraIntegrationTemplate from "../templates/AsstraIntegrationTemplate/AsstraIntegrationTemplate";

const initialGridConfig: InitialGridConfig = {
    sortBy: [],
    groupBy: [],
    pageSize: 25,
    headerTemplate: GridDefaultHeaderTemplate,
    cellTemplate: GridDefaultCellTemplate,
    groupTemplate: GroupBaseTemplate,
    columns: [
        {
            visible: true,
            field: 'isNew',
            title: ' ',
            width: 10,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            template: IsNewTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 0},
                {role: UserRole.Forwarder, order: 0},
                {role: UserRole.ServiceProvider, order: 0}
            ]
        },
        {
            visible: true,
            field: GRID_SELECTED_FIELD,
            title: ' ',
            width: 25,
            locked: true,
            constantlyLocked: true,
            allowReordering: false,
            allowSorting: false,
            allowGrouping: false,
            allowResizing: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 1},
                {role: UserRole.Forwarder, order: 1},
                {role: UserRole.ServiceProvider, order: 1},
                {role: UserRole.Client, order: 1}
            ]
        },
        {
            visible: true,
            field: 'shipmentNumber',
            title: 'shipment.grid.order-number-label',
            width: 200,
            locked: false,
            template: ShipmentNumberTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 2},
                {role: UserRole.Forwarder, order: 2},
                {role: UserRole.ServiceProvider, order: 2},
                {role: UserRole.Client, order: 3}
            ]
        },
        {
            visible: true,
            field: 'needTracking',
            title: 'shipment.grid.need-tracking-label',
            width: 75,
            locked: false,
            template: NeedTrackingTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 3},
                {role: UserRole.Forwarder, order: 3}
            ]
        },
        {
            visible: true,
            field: 'indicator',
            title: 'shipment.grid.indicator-label',
            width: 75,
            locked: false,
            allowSorting: true,
            template: IndicatorTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 4},
                {role: UserRole.Forwarder, order: 4},
                {role: UserRole.ServiceProvider, order: 4}
            ]
        },
        {
            visible: true,
            field: 'hasTracking',
            title: 'shipment.grid.has-tracking-label',
            width: 75,
            locked: false,
            template: TrackingTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 5},
                {role: UserRole.Forwarder, order: 5},
                {role: UserRole.Client, order: 22}
            ]
        },
        {
            visible: true,
            field: 'tags',
            title: 'shipment.grid.tags-label',
            width: 200,
            locked: false,
            template: TagsGridCellTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 6},
                {role: UserRole.Forwarder, order: 6}
            ]
        },
        {
            visible: true,
            field: 'tOrder',
            title: 'shipment.grid.t-order-label',
            width: 75,
            locked: false,
            template: TransportOrderTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 7},
                {role: UserRole.Forwarder, order: 7},
                {role: UserRole.ServiceProvider, order: 7}
            ]
        },
        {
            visible: true,
            field: 'job',
            title: 'shipment.grid.job-label',
            width: 150,
            locked: false,
            template: JobCellTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 8},
                {role: UserRole.Forwarder, order: 8}
            ]
        },
        {
            visible: true,
            field: 'refNum',
            title: 'shipment.grid.ref-num-label',
            width: 150,
            locked: false,
            template: RefNumTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 9},
                {role: UserRole.Forwarder, order: 9},
                {role: UserRole.Client, order: 2}
            ]
        },
        {
            visible: true,
            field: 'loadingNum',
            title: 'shipment.grid.loading-number-label',
            width: 150,
            locked: false,
            template: LoadingNumberTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 10},
                {role: UserRole.Forwarder, order: 10},
                {role: UserRole.ServiceProvider, order: 10}
            ]
        },
        {
            visible: true,
            field: 'pickupDate',
            title: 'shipment.grid.pickup-date-label',
            width: 150,
            locked: false,
            template: DateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 11},
                {role: UserRole.Forwarder, order: 11},
                {role: UserRole.ServiceProvider, order: 11},
                {role: UserRole.Client, order: 14}
            ]
        },
        {
            visible: true,
            field: 'deliveryDate',
            title: 'shipment.grid.delivery-date-label',
            width: 150,
            locked: false,
            template: DateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 12},
                {role: UserRole.Forwarder, order: 12},
                {role: UserRole.ServiceProvider, order: 12},
                {role: UserRole.Client, order: 15}
            ]
        },
        {
            visible: true,
            field: 'sourceCountry2',
            title: 'shipment.grid.source-country-label',
            width: 75,
            locked: false,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 13},
                {role: UserRole.Forwarder, order: 13},
                {role: UserRole.ServiceProvider, order: 13},
                {role: UserRole.Client, order: 8}
            ]
        },
        {
            visible: true,
            field: 'destCountry2',
            title: 'shipment.grid.destination-country-label',
            width: 75,
            locked: false,
            template: CountryTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 14},
                {role: UserRole.Forwarder, order: 14},
                {role: UserRole.ServiceProvider, order: 14},
                {role: UserRole.Client, order: 9}
            ]
        },
        {
            visible: true,
            field: 'sourceCity',
            title: 'shipment.grid.source-city-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 15},
                {role: UserRole.Forwarder, order: 15},
                {role: UserRole.ServiceProvider, order: 15},
                {role: UserRole.Client, order: 10}
            ]
        },
        {
            visible: true,
            field: 'destCity',
            title: 'shipment.grid.destination-city-label',
            width: 75,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 16},
                {role: UserRole.Forwarder, order: 16},
                {role: UserRole.ServiceProvider, order: 16},
                {role: UserRole.Client, order: 11}
            ]
        },
        {
            visible: true,
            field: 'costBrutto',
            title: 'shipment.grid.cost-brutto-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 17},
                {role: UserRole.Forwarder, order: 17}
            ]
        },
        {
            visible: true,
            field: 'costNetto',
            title: 'shipment.grid.cost-brutto-label',
            width: 150,
            locked: false,
            template: CostNettoTemplate,
            roles: [
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 17}
            ]
        },
        {
            visible: true,
            field: 'costNetto',
            title: 'shipment.grid.cost-netto-label',
            width: 150,
            locked: false,
            template: CostNettoTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 18},
                {role: UserRole.Forwarder, order: 18}
            ]
        },
        {
            visible: true,
            field: 'client',
            title: 'shipment.grid.client-label',
            width: 200,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 19},
                {role: UserRole.Forwarder, order: 19}
            ]
        },
        {
            visible: true,
            field: 'serviceProvider',
            title: 'shipment.grid.service-provider-label',
            width: 200,
            locked: false,
            template: ServiceProviderTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 20},
                {role: UserRole.Forwarder, order: 20},
                {role: UserRole.ServiceProvider, order: 20},
                {role: UserRole.Client, order: 17}
            ]
        },
        {
            visible: true,
            field: 'executer',
            title: 'shipment.grid.executer-label',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 21},
                {role: UserRole.Forwarder, order: 21}
            ]
        },
        {
            visible: true,
            field: 'forwarder',
            title: 'offer.grid.forwarderAsstrA',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 21},
                {role: UserRole.Client, order: 16}
            ]
        },
        {
            visible: true,
            field: 'forwardingAssistant',
            title: 'offer.grid.forwardingAssistant',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.ServiceProvider,
                //UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.ServiceProvider, order: 22},
                //{role: UserRole.Client, order: 16}
            ]
        },
        {
            visible: true,
            field: 'orderManager',
            title: 'shipment.grid.order-manager-label',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 22},
                {role: UserRole.Forwarder, order: 22}
            ]
        },
        {
            visible: true,
            field: 'shipmentEvent',
            title: 'shipment.grid.shipment-event-label',
            width: 150,
            locked: false,
            template: ShipmentEventTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 23},
                {role: UserRole.Forwarder, order: 23},
                {role: UserRole.ServiceProvider, order: 23},
                {role: UserRole.Client, order: 6}
            ]
        },
        {
            visible: true,
            field: 'transport',
            title: 'shipment.grid.transport-label',
            width: 150,
            locked: false,
            template: TransportTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 24},
                {role: UserRole.Forwarder, order: 24},
                {role: UserRole.ServiceProvider, order: 24},
                {role: UserRole.Client, order: 18}
            ]
        },
        {
            visible: true,
            field: 'shipmentAddress',
            title: 'shipment.grid.shipment-address-label',
            width: 150,
            locked: false,
            template: ShipmentAddressTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.Client
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 25},
                {role: UserRole.Forwarder, order: 25},
                {role: UserRole.Client, order: 4}
            ]
        },



        {
            visible: false,
            field: 'customerManager',
            title: 'shipment.grid.customer-manager-label',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 26},
                {role: UserRole.Forwarder, order: 26}
            ]
        },

        {
            visible: true,
            field: 'shipmentAddressDate',
            title: 'shipment.grid.shipment-address-date-label',
            width: 150,
            locked: false,
            template: ShipmentAddressDateTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.Client
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 27},
                {role: UserRole.Forwarder, order: 27},
                {role: UserRole.Client, order: 5}
            ]
        },
        {
            visible: true,
            field: 'shipmentEventDate',
            title: 'shipment.grid.shipment-event-date-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 28},
                {role: UserRole.Forwarder, order: 28},
                {role: UserRole.ServiceProvider, order: 28},
                {role: UserRole.Client, order: 7}
            ]
        },
        {
            visible: false,
            field: 'cmr',
            title: 'shipment.grid.cmr-label',
            width: 75,
            locked: false,
            template: CmrTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 29},
                {role: UserRole.Forwarder, order: 29},
                {role: UserRole.ServiceProvider, order: 29}
            ]
        },
        {
            visible: false,
            field: 'eta',
            title: 'shipment.grid.eta-label',
            width: 150,
            locked: false,
            template: CustomsEtaTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 30},
                {role: UserRole.Forwarder, order: 30}
            ]
        },
        {
            visible: false,
            field: 'etaDeparture',
            title: 'shipment.grid.eta-departure-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 31},
                {role: UserRole.Forwarder, order: 31}
            ]
        },
        {
            visible: false,
            field: 'etaArrival',
            title: 'shipment.grid.eta-arrival-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 32},
                {role: UserRole.Forwarder, order: 32}
            ]
        },
        {
            visible: false,
            field: 'etaDepartureStatus',
            title: 'shipment.grid.eta-departure-status-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 33},
                {role: UserRole.Forwarder, order: 33}
            ]
        },




        {
            visible: false,
            field: 'otmStatus',
            title: 'shipment.grid.otm-status-label',
            width: 150,
            locked: false,
            allowSorting: true,
            template: OtmStatusTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 34},
                {role: UserRole.Forwarder, order: 34},
                {role: UserRole.ServiceProvider, order: 34}
            ]
        },
        {
            visible: false,
            field: 'forwarderAgent',
            title: 'shipment.grid.forwarder-agent-label',
            width: 150,
            locked: false,
            template: ManagerTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 35},
                {role: UserRole.Forwarder, order: 35}
            ]
        },
        {
            visible: false,
            field: 'driver',
            title: 'shipment.grid.driver-label',
            width: 100,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 36},
                {role: UserRole.Forwarder, order: 36}
            ]
        },
        {
            visible: false,
            field: 'mobile',
            title: 'shipment.grid.mobile-label',
            width: 100,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 37},
                {role: UserRole.Forwarder, order: 37}
            ]
        },
        {
            visible: false,
            field: 'licence',
            title: 'shipment.grid.licence-label',
            width: 100,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 38},
                {role: UserRole.Forwarder, order: 38}
            ]
        },
        {
            visible: false,
            field: 'passport',
            title: 'shipment.grid.passport-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 39},
                {role: UserRole.Forwarder, order: 39}
            ]
        },
        {
            visible: false,
            field: 'releaseNumber',
            title: 'shipment.grid.release-number-label',
            width: 150,
            locked: false,
            template: ReleaseNumberTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 40},
                {role: UserRole.Forwarder, order: 40},
                {role: UserRole.ServiceProvider, order: 40}
            ]
        },
        {
            visible: false,
            field: 'pin',
            title: 'shipment.grid.pin-label',
            width: 75,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 41},
                {role: UserRole.Forwarder, order: 41},
                {role: UserRole.ServiceProvider, order: 41}
            ]
        },
        {
            visible: false,
            field: 'bcNotes',
            title: 'shipment.grid.bc-notes-label',
            width: 100,
            locked: false,
            template: BorderCrossingTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 42},
                {role: UserRole.Forwarder, order: 42}
            ],
        },
        {
            visible: false,
            field: 'notes',
            title: 'shipment.grid.notes-label',
            width: 150,
            locked: false,
            template: NotesTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 43},
                {role: UserRole.Forwarder, order: 43}
            ]
        },
        {
            visible: true,
            field: 'sourceDistrict',
            title: 'shipment.grid.source-district-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 44},
                {role: UserRole.Forwarder, order: 44},
                {role: UserRole.ServiceProvider, order: 44},
                {role: UserRole.Client, order: 12}
            ]
        },
        {
            visible: true,
            field: 'destDistrict',
            title: 'shipment.grid.destination-district-label',
            width: 150,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 45},
                {role: UserRole.Forwarder, order: 45},
                {role: UserRole.ServiceProvider, order: 45},
                {role: UserRole.Client, order: 13}
            ]
        },
        {
            visible: true,
            field: 'paidStatus',
            title: 'shipment.grid.paid-status-label',
            width: 75,
            locked: false,
            template: PaidStatusTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 46},
                {role: UserRole.Forwarder, order: 46},
                {role: UserRole.ServiceProvider, order: 46}
            ]
        },
        {
            visible: true,
            field: 'equipment',
            title: 'shipment.grid.equipment-label',
            width: 200,
            locked: false,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client,
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 47},
                {role: UserRole.Forwarder, order: 47},
                {role: UserRole.ServiceProvider, order: 47},
                {role: UserRole.Client, order: 19}
            ]
        },

        {
            visible: true,
            field: 'totalWeight',
            title: 'shipment.grid.weight',
            width: 100,
            locked: false,
            allowSorting: true,
            /*template: CostTemplate,*/
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 48},
                {role: UserRole.Forwarder, order: 48},
                {role: UserRole.ServiceProvider, order: 48},
                {role: UserRole.Client, order: 20}
            ]
        },

        {
            visible: true,
            field: 'cost',
            title: 'shipment.grid.cost',
            width: 205,
            locked: false,
            allowSorting: true,
            template: CostTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider,
                UserRole.Client
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 49},
                {role: UserRole.Forwarder, order: 49},
                {role: UserRole.ServiceProvider, order: 49},
                {role: UserRole.Client, order: 21}
            ]
        },

        {
            visible: false,
            field: 'asstraIntegration',
            title: 'shipment.grid.asstra-integration',
            width: 100,
            locked: false,
            allowSorting: true,
            template: AsstraIntegrationTemplate,
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 50},
                {role: UserRole.Forwarder, order: 50},
                {role: UserRole.ServiceProvider, order: 50}
            ]
        },

        {
            visible: false,
            field: 'responsibleForVisibility',
            title: 'shipment.grid.responsible-for-visibility',
            width: 200,
            locked: false,
            allowSorting: true,
            /*template: AsstraIntegrationTemplate,*/
            roles: [
                UserRole.Administrator,
                UserRole.Forwarder,
                UserRole.ServiceProvider
            ],
            orderByRoles: [
                {role: UserRole.Administrator, order: 51},
                {role: UserRole.Forwarder, order: 51},
                {role: UserRole.ServiceProvider, order: 51}
            ]
        }



























    ]
};

const getShipmentInitialGridConfig = (role: UserRole) => {
    return convertToGridConfig(role, {
        groupBy: [...initialGridConfig.groupBy],
        sortBy: [...initialGridConfig.sortBy.map(item => ({...item}))],
        columns: [...initialGridConfig.columns.map(item => ({...item}))],
        pageSize: initialGridConfig.pageSize,
        headerTemplate: initialGridConfig.headerTemplate,
        cellTemplate: initialGridConfig.cellTemplate,
        groupTemplate: initialGridConfig.groupTemplate
    });
};

export default getShipmentInitialGridConfig;
