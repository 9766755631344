export const formatCurrencyNumber = (value: number, currency: string) => {
    return new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'code'
    }).format(value);
};


export const parseMoney = (value: string) => {
    if (!value)
        return null;

    // let n = 1.1;
    // let result = n.toLocaleString().substring(1, 2);

    // if (result == '.') {
    //     return Number(value.replace(/,/g, '.').replace(/\s/g, ''))
    // }

    // return Number(value.replace(/\./g, '.').replace(/\s/g, ''))

    const valueWithoutSpaces = value.replace(/\s/g, ''); // Убираем пробелы

    if (valueWithoutSpaces.includes(',')) {
        // Предполагаем, что запятая - десятичный разделитель
        const replacedValue = valueWithoutSpaces.replace(/,/g, '.');
        return Number(replacedValue);
    } else if (valueWithoutSpaces.includes('.')) {
        // Предполагаем, что точка - десятичный разделитель
        return Number(valueWithoutSpaces);
    } else {
        // Нет ни точек, ни запятых, просто целое число
        return Number(valueWithoutSpaces);
    }
}
