import React from 'react';
import {IShipment} from "../../../../../../../../../app/interfaces/shipment/IShipment";
import ForwarderField
    from "../../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type ForwardingAssistantProps = {
    shipment: IShipment
}

const ForwardingAssistant: React.FC<ForwardingAssistantProps> = ({
                                                             shipment
                                                         }) => {
    return (
        <div className={'details-box-field-value'}>
            {shipment.forwardingAssistantUser !== null 
                && shipment.forwardingAssistantUser !== undefined 
                && shipment.forwardingAssistantUser.name !== null 
                && shipment.forwardingAssistantUser.name !== ''
                ? <ForwarderField user={shipment.forwardingAssistantUser}
                                  chatReplyTo={{
                                      type: 'SHIPMENT',
                                      id: shipment.orderReleaseGid?.replace('AAG.', '') ?? '',
                                      idExt: shipment.shipmentGid,
                                      description: shipment.chatDescription
                                  }}/>
                : null
            }
        </div>
    );
};

export default ForwardingAssistant;