import React from 'react';
import { IOffer } from "../../../../../../../../app/interfaces/offer/IOffer";
import ForwarderField from "../../../../../../../../components/shared/form/detailsFields/ForwarderField/ForwarderField";

type OfferForwardingAssistantProps = {
    offer: IOffer;
};

const OfferForwardingAssistant: React.FC<OfferForwardingAssistantProps> = ({ offer }) => {

    return (
        <div className={'details-box-item-field-value'}>
            {offer.forwardingAssistantUser !== null
                && offer.forwardingAssistantUser !== undefined
                && offer.forwardingAssistantUser.name !== null
                && offer.forwardingAssistantUser.name !== ''
                ? <ForwarderField user={offer.forwardingAssistantUser}
                    chatReplyTo={{
                        type: 'RELEASE',
                        id: '',
                        idExt: null,
                        description: offer.chatDescription
                    }} />
                : null
            }
        </div>
    );
};

export default OfferForwardingAssistant;
