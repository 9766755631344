import React from 'react';
import { IShipment } from "../../../../../../../app/interfaces/shipment/IShipment";
import DetailsBox from "../../../../../../../components/ui/DetailsBox/DetailsBox";
import DetailsBoxColumn
    from "../../../../../../../components/ui/DetailsBox/components/DetailsBoxColumn/DetailsBoxColumn";
import useMediaQuery from "../../../../../../../hooks/useMediaQuery/useMediaQuery";
import LatestEvent from "../../../../../../../components/shared/form/LatestEvent/LatestEvent";
import { useAppSelector } from "../../../../../../../app/store";
import { selectCurrentUser } from "../../../../../../../features/authentication/authenticationSliceSelectors";
import { getUserRole } from "../../../../../../../helpers/user";
import { useTranslation } from "react-i18next";
import ShipmentGid from "./fields/ShipmentGid/ShipmentGid";
import { UserRole } from "../../../../../../../app/enums/UserRole";
import SupplierManager from "./fields/SupplierManager/SupplierManager";
import Supplier from "./fields/Supplier/Supplier";
import CustomerOrderRefNumber from "./fields/CustomerOrderRefNumber/CustomerOrderRefNumber";
import LoadingNumber from "./fields/LoadingNumber/LoadingNumber";
import Customer from "./fields/Customer/Customer";
import Rate from "./fields/Rate/Rate";
import CostNetto from "./fields/CostNetto/CostNetto";
import TagFormField from "../../../../../../../components/shared/components/Tag/components/TagFormField/TagFormField";
import Notes from "./fields/Notes/Notes";
import moment from "moment";
import { OrderType } from "../../../../../../../app/enums/OrderType";
import ShowTimeSlot from "../ShowTimeSlot/ShowTimeSlot";
import ForwardingAssistant from './fields/ForwardingAssistant/ForwardingAssistant';

type ShipmentDetailsBoxProps = {
    shipment: IShipment;
    isSidePanelDetails: boolean;
}

const ShipmentDetailsBox: React.FC<ShipmentDetailsBoxProps> = ({
    isSidePanelDetails,
    shipment
}) => {
    const { t } = useTranslation();

    const user = useAppSelector(selectCurrentUser);
    const isMaxWidth960 = useMediaQuery('(max-width: 960px)');
    const isMaxWidth600 = useMediaQuery('(max-width: 600px)');

    if (!user) {
        return null;
    }

    const role = getUserRole(user);

    return (
        <DetailsBox id={'shipment-details-data-box'} isSidePanelDetails={isSidePanelDetails || isMaxWidth960}>
            <DetailsBoxColumn center>
                <div className={'details-box-default-container'}>
                    {!isMaxWidth600 &&
                        <div className={'details-box-default-container-columns'}
                            data-count={'2'}>
                            <div>
                                <div className={'details-box-default-container-item'} id={'details-box-shipment-gid'}>
                                    <label>
                                        {t("shipment.form.shipment-gid")}
                                    </label>
                                    <div>
                                        <ShipmentGid shipment={shipment} />
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                    id={'details-box-supplier-manager'}>
                                    <label>
                                        {role === UserRole.Administrator || role === UserRole.Forwarder
                                            ? t("shipment.form.supplier-manager")
                                            : t("offer.grid.forwarderAsstrA")
                                        }
                                    </label>
                                    <div>
                                        <SupplierManager shipment={shipment} />
                                    </div>
                                </div>

                                {role === UserRole.ServiceProvider &&
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-forwarding-assistant'}>
                                        <label>
                                            {t("offer.grid.forwardingAssistant")}
                                        </label>
                                        <div>
                                            <ForwardingAssistant shipment={shipment} />
                                        </div>
                                    </div>
                                }


                                <div className={'details-box-default-container-item'} id={'details-box-supplier'}>
                                    <label>
                                        {t("shipment.form.supplier")}
                                    </label>
                                    <div>
                                        <Supplier shipment={shipment} />
                                    </div>
                                </div>

                                {(role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'} id={'details-box-rate'}>
                                        <label>
                                            {t("shipment.form.rate")}
                                        </label>
                                        <div>
                                            <CostNetto shipment={shipment} />
                                        </div>
                                    </div> :
                                    <div className={'details-box-default-container-item'} id={'details-box-rate'}>
                                        <label>
                                            {t("shipment.form.rate")}
                                        </label>
                                        <div>
                                            <Rate shipment={shipment} />
                                        </div>
                                    </div>
                                }
                                {role !== UserRole.ServiceProvider && role !== UserRole.Client && shipment.hasShipment &&
                                    <div>
                                        <TagFormField orderReleaseGid={shipment.shipmentGid ?? ''}
                                            orderType={OrderType.Shipment}
                                            tags={shipment.tags} />
                                    </div>
                                }
                            </div>
                            <div>
                                {role !== UserRole.ServiceProvider &&
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-customer-order-ref-num'}>
                                        <label>
                                            {t("shipment.form.customer-order-ref-number")}
                                        </label>
                                        <div>
                                            <CustomerOrderRefNumber shipment={shipment} />
                                        </div>
                                    </div>
                                }

                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ?
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-loading-number'}>
                                        <label>
                                            {t("shipment.form.loading-number")}
                                        </label>
                                        <div>
                                            <LoadingNumber shipment={shipment} />
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== UserRole.Client &&
                                    <div className={'details-box-default-container-item'} id={'details-box-customer'}>
                                        <label>
                                            {t("shipment.form.customer")}
                                        </label>
                                        <div>
                                            <Customer shipment={shipment} />
                                        </div>
                                    </div>
                                }
                                {(role === UserRole.Forwarder || role === UserRole.Administrator) && shipment.hasShipment && shipment.hasBookingData
                                    ?
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-time-slot'}>

                                        <ShowTimeSlot shipment={shipment} />

                                    </div>
                                    : null
                                }
                                {role !== UserRole.ServiceProvider && role !== UserRole.Client && shipment.hasShipment &&
                                    <div >
                                        <Notes notes={shipment.notes}
                                            notesInfo={shipment.notesUser + " " + moment.utc(shipment.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                                            orderReleaseGid={shipment.orderReleaseGid ?? ''}
                                            shipmentGid={shipment.shipmentGid ?? ''} />
                                    </div>
                                }
                            </div>
                        </div>
                    }

                    {isMaxWidth600 &&
                        <div className={'details-box-default-container-columns'}
                            data-count={'1'}>
                            <div>
                                <div className={'details-box-default-container-item'} id={'details-box-shipment-gid'}>
                                    <label>
                                        {t("shipment.form.shipment-gid")}
                                    </label>
                                    <div>
                                        <ShipmentGid shipment={shipment} />
                                    </div>
                                </div>

                                <div className={'details-box-default-container-item'}
                                    id={'details-box-supplier-manager'}>
                                    <label>
                                        {role === UserRole.Administrator || role === UserRole.Forwarder
                                            ? t("shipment.form.supplier-manager")
                                            : t("offer.grid.forwarderAsstrA")
                                        }
                                    </label>
                                    <div>
                                        <SupplierManager shipment={shipment} />
                                    </div>
                                </div>

                                {role === UserRole.ServiceProvider &&
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-forwarding-assistant'}>
                                        <label>
                                            {t("offer.grid.forwardingAssistant")}
                                        </label>
                                        <div>
                                            <ForwardingAssistant shipment={shipment} />
                                        </div>
                                    </div>
                                }

                                <div className={'details-box-default-container-item'} id={'details-box-supplier'}>
                                    <label>
                                        {t("shipment.form.supplier")}
                                    </label>
                                    <div>
                                        <Supplier shipment={shipment} />
                                    </div>
                                </div>

                                {(role === UserRole.Forwarder || role === UserRole.Administrator)
                                    ? <div className={'details-box-default-container-item'} id={'details-box-rate'}>
                                        <label>
                                            {t("shipment.form.rate")}
                                        </label>
                                        <div>
                                            <CostNetto shipment={shipment} />
                                        </div>
                                    </div> :
                                    <div className={'details-box-default-container-item'} id={'details-box-rate'}>
                                        <label>
                                            {t("shipment.form.rate")}
                                        </label>
                                        <div>
                                            <Rate shipment={shipment} />
                                        </div>
                                    </div>
                                }

                                {role !== UserRole.ServiceProvider &&
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-customer-order-ref-num'}>
                                        <label>
                                            {t("shipment.form.customer-order-ref-number")}
                                        </label>
                                        <div>
                                            <CustomerOrderRefNumber shipment={shipment} />
                                        </div>
                                    </div>
                                }

                                {role === UserRole.Forwarder || role === UserRole.Administrator
                                    ?
                                    <div className={'details-box-default-container-item'}
                                        id={'details-box-loading-number'}>
                                        <label>
                                            {t("shipment.form.loading-number")}
                                        </label>
                                        <div>
                                            <LoadingNumber shipment={shipment} />
                                        </div>
                                    </div>
                                    : null
                                }

                                {role !== UserRole.Client &&
                                    <div className={'details-box-default-container-item'} id={'details-box-customer'}>
                                        <label>
                                            {t("shipment.form.customer")}
                                        </label>
                                        <div>
                                            <Customer shipment={shipment} />
                                        </div>
                                    </div>
                                }
                                {role !== UserRole.ServiceProvider && role !== UserRole.Client && shipment.hasShipment &&
                                    <div>
                                        <TagFormField orderReleaseGid={shipment.shipmentGid ?? ''}
                                            orderType={OrderType.Shipment}
                                            tags={shipment.tags} />
                                    </div>
                                }
                                {role !== UserRole.ServiceProvider && role !== UserRole.Client && shipment.hasShipment &&
                                    <div >
                                        <Notes notes={shipment.notes}
                                            notesInfo={shipment.notesUser + " " + moment.utc(shipment.notesDate, 'DD.MM.YY HH:mm').local().format('DD.MM.YYYY HH:mm')}
                                            orderReleaseGid={shipment.orderReleaseGid ?? ''}
                                            shipmentGid={shipment.shipmentGid ?? ''} />
                                    </div>
                                }
                            </div>
                        </div>
                    }





                </div>
            </DetailsBoxColumn>
            {/*{(isSidePanelDetails || isMaxWidth600) && !shipment.latestEvent && (!shipment.currentPosition || !shipment.currentPosition.addressChangedOnLocal) ? <></> :*/}
            {/*    <DetailsBoxColumn center >*/}
            {/*    <div className={'details-box-default-container'}>*/}
            {/*    <LatestEvent currentPosition={shipment.currentPosition}*/}
            {/*                 latestEvent={shipment.latestEvent}*/}
            {/*                 stopPoints={shipment.stopPoints ?? []}/>*/}

            {/*    </div>*/}
            {/*</DetailsBoxColumn>}*/}
        </DetailsBox>
    );
};

export default ShipmentDetailsBox;
